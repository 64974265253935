import axios from 'axios'
const config = require(`@/config`)
const apiAuthToken = `Basic ${Buffer.from(`${config.authenService.username}:${config.authenService.password}`).toString("base64")}`
const apiLmsManagementToken = `Basic ${Buffer.from(`${config.lmsManagementService.username}:${config.lmsManagementService.password}`).toString("base64")}`
const apiBaakToken = `Basic ${Buffer.from(`${config.BaakService.username}:${config.BaakService.password}`).toString("base64")}`

export const apiBaakService = axios.create({
    baseURL: `${config.BaakService.uri}`,
    headers: {
        'Authorization': apiBaakToken
    },
})

export const apiAuth = axios.create({
    baseURL: `${config.authenService.uri}`,
    headers: {
        'Authorization': apiAuthToken
    },
})

export const apiGateway = axios.create({
    baseURL: `${config.gateway.uri}`
})

export const apiLmsManagement = axios.create({
    baseURL: `${config.lmsManagementService.uri}`,
    headers: {
        'Authorization': apiLmsManagementToken
    },
})

export const apiGitLab = axios.create({
    baseURL: `${config.gitlabService.url}`,
    headers: {
        'Authorization': `Bearer ${config.gitlabService.accessToken}`
    },
})

