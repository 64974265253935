import { apiAuth } from "@/axios.js"


export default {
    async getCpmToken(redirect_uri, queryCode) {
        try {
            let data = JSON.parse('{}')
            data['redirectUri'] = `${redirect_uri}`
            data['code'] = `${queryCode}`
            let response = await apiAuth.post(`/authen/signin`, data)
            return response['data']
        } catch (error) {
            console.log(`error-:`, error.message)
            return false
        }
    },
    async getCpmRefreshToken(refreshToken) {
        try {
            let data = {
                "refreshToken": refreshToken
            }
            let response = await apiAuth.post(`/authen/token`, data)
            return response['data']
        } catch (error) {
            console.log(`error-:`, error.message)
            return false
        }
    },
    async getProfile(email) {
        let returnData = {}
        returnData['email'] = email
        return JSON.parse(JSON.stringify(returnData))
    },
    
    async loginOauthCMU( regBody) {
        try {
            let response = await apiAuth.post(`/authen/signin/cmuoauth`,regBody)
            response['data'].status = true
            return response['data']
        } catch (error) {
            return {
                status: false,
            }
        }
    }
}