import { apiGitLab } from "@/axios.js"

export default {
    async getRelease() {
        try {
            let response = await apiGitLab.get(`/releases`, {})
            response['data'].status = true
            return response['data']
        } catch (error) {
            return {
                status: false,
                message: error.response.data.error.code
            }
        }
    }
}                       