const en = {
    name : "Firstname",
    lastName : "Lastname",
    back : "back",
    relaseNote : {
        relaseNote : "Release Notes",
        lastUpdate : "Latest update" , 
        copyRight : "Copyright"
    }

}
export default en;