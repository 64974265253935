import Vue from 'vue'
import VueRouter from 'vue-router'
import apiGatewayCpmService from "@/services/apiGatewayService"
import authApiCpmService from "@/services/authCpmService"
const config = require(`@/config`)
import CryptoJS from 'crypto-js'

const TheContainer = () =>
    import ("@/containers/TheContainer")
const CourseDetail = () =>
    import ('@/views/CourseDetail')
const CourseLMS = () =>
    import ('@/views/CourseLms')
const ActivateMsTeams = () =>
    import ('@/views/MsTeamsActivate')
const CourseMsTeams = () =>
    import ('@/views/CourseMsTeams')
const CourseCMUExam = () =>
    import ('../views/CourseCMUExam')
const AdminImport = () =>
    import ('@/views/admin/AdminImport')
const AdminConsole = () =>
    import ('@/views/admin/AdminConsole')
const Home = () =>
    import ('@/views/Home')
const Signin = () =>
    import ('@/views/auth/SignIn')
const Signout = () =>
    import ('@/views/auth/SignOut')
const Callback = () =>
    import ('@/views/auth/Callback')
const CourseInstrusctorManagement = () =>
    import ('@/views/CourseInstrusctorManagement')
const TokenShow = () =>
    import ('@/views/TokenShow')
const Activate = () =>
    import ('@/views/Activate')
const AdminManagement = () => 
    import ('@/views/admin/AdminManagement')
const OEMCompoment = () =>
    import ('@/views/CourseOEM')
const StudentList = () => import('@/views/StudentList')

Vue.use(VueRouter)

const router = new VueRouter({
    mode: "history",
    linkActiveClass: "active",
    scrollBehavior: () => ({ y: 0 }),
    routes: configRoutes(),
    base: process.env.BASE_URL
})

function configRoutes() {
    return [{
            path: "/",
            redirect: "/",
            name: "Container",
            component: TheContainer,
            children: [{
                    path: '/',
                    name: 'Home',
                    meta: { sidebar: false, hamburger: false },
                    component: Home
                },
                {
                    path: '/showtoken',
                    name: 'TokenShow',
                    meta: { sidebar: false, hamburger: false },
                    component: TokenShow
                },
                {
                    path: '/activate',
                    name: 'Activate',
                    meta: { sidebar: false, hamburger: false },
                    component: Activate
                },
                {
                    path: '/activate-microsoft-teams',
                    name: 'Activate MsTeams',
                    meta: { sidebar: false, hamburger: false },
                    component: ActivateMsTeams
                },
                {
                    path: '/admin-import',
                    name: 'Admin import',
                    meta: { sidebar: false, hamburger: false },
                    component: AdminImport
                },
                {
                    path: '/admin-console',
                    name: 'Admin console',
                    meta: { sidebar: false, hamburger: false },
                    component: AdminConsole
                },
                {
                    path: '/course-detail',
                    name: 'CourseDetail',
                    redirect: "/",
                    component: CourseDetail
                },{
                    path: '/admin-management',
                    name: 'Admin Management',
                    meta: { sidebar: false, hamburger: false },
                    component: AdminManagement
                },
                {
                    path: '/courses',
                    name: 'CourseDetail',
                    // component: CourseDetail,
                    component: {
                        render(c) {
                            return c("router-view")
                        },
                    },
                    children: [
                        {
                            path: "/",
                            redirect: "/",
                        },
                        {
                            path: ":courseId",
                            redirect: ":courseId/lms",
                        },
                        {
                            path: ":courseId/lms",
                            name: "Learning Managemnt System",
                            component: CourseLMS,
                        },
                        {
                            path: ":courseId/teams",
                            name: "Microsoft Teams",
                            component: CourseMsTeams,
                        },
                        {
                            path: ':courseId/course-management',
                            name: 'Instructor Management',
                            component : CourseInstrusctorManagement 
                        },
                        {
                            path: ":courseId/oem",
                            name: "Online Exam Manager",
                            component: OEMCompoment,
                        },
                        {
                            path: ":courseId/exam",
                            name: "CMU Exam",
                            component: CourseCMUExam,
                        },
                        {
                            path: ":courseId/students",
                            name: "Students",
                            component: StudentList,
                        },
                    ]
                }
            ],
        },
        {
            path: "/signin",
            component: {
                render(c) {
                    return c("router-view")
                },
            },
            children: [{
                path: "/",
                name: "Signin",
                component: Signin,
            }, ],
        },
        {
            path: "/signout",
            component: {
                render(c) {
                    return c("router-view")
                },
            },
            children: [{
                path: "/",
                name: "Signout",
                component: Signout,
            }, ],
        },
        {
            path: "/callback",
            component: {
                render(c) {
                    return c("router-view")
                },
            },
            children: [{
                path: "/",
                name: "Callback",
                component: Callback,
            }, ],
        },
    ]
}

async function checkTokenIsNotExpire (token) {
    if(token){
        try {
            await apiGatewayCpmService.getProfile(token)
            return true
        } catch (error) {
            const oldRefreshToken = localStorage.getItem('refreshToken')
            const newToken = await authApiCpmService.getCpmRefreshToken(oldRefreshToken)
            if(newToken){
                localStorage.setItem('accessToken',newToken['accessToken'])
                localStorage.setItem('refreshToken',newToken['refreshToken'])
                return true
            }
            return false
        }
    }
}

router.beforeEach( async (to, from, next) => {
    const whiteList = ['Signin', 'Signout', 'Callback', 'Page404']
    let token = localStorage.getItem("accessToken")
    

    try{
        const grantAdmin = await apiGatewayCpmService.getGrantAdmin(token);
        let check_router = localStorage.getItem("router");

       
        // if(grantAdmin.status){
        if(grantAdmin.status){
            localStorage.setItem("grantAdmin", true)
            if(!grantAdmin.isApprove ){
                if(to.name != "Activate" && !whiteList.includes(to.name)){
                    console.log("non-approve")
                    if(check_router === "false"){
                        next({ name: "Activate" })
                    }
                }
            }
        }else{
            localStorage.setItem("grantAdmin", false)
        }
    }catch (error){
        
        console.log("error :>" , error)
    }    

    if(to.query){
        // console.log("check gg",to)
        if(to.query.token){
            localStorage.setItem('cmuMobileToken', to.query.token)
            const response = await authApiCpmService.loginOauthCMU({
                "oauthToken": to.query.token
            })
            // console.log("response:>" , response)
            if(response.status){
                localStorage.setItem('accessToken',response['accessToken'])
                localStorage.setItem('refreshToken',response['refreshToken']) 
                localStorage.setItem("beforepath", to.fullPath)
                let profileStr = JSON.stringify(response)
                localStorage.setItem("profile", CryptoJS.AES.encrypt(profileStr, config.aesKey.key).toString())
                localStorage.setItem('router', false)
                next({ name: "TokenShow" })
            }else{
                next({ name: "Signin" })
            }
            // localStorage.setItem('accessToken',response['accessToken'])
            // localStorage.setItem('refreshToken',response['refreshToken'])   
            // next({ name: "TokenShow" })
        }else{
            if(to.name !== 'TokenShow' ){
                if(!await checkTokenIsNotExpire(token)){
                    if(!whiteList.includes(to.name) ){
                        // console.log("TokenShow",to.name)
                        localStorage.setItem("beforepath", to.fullPath)
                        localStorage.setItem('router', false)
                        next({ name: "Signin" })
                    }
                }
            }
        }
    }
    next()
    // console.log("gg",to)
    
    
})

export default router