<template>
    <!-- <v-row justify="center"> -->
    <div justify="center">
        <v-dialog
            v-model="showModal"
            max-width="600"    
            @click:outside="onClose"
        >
            <v-card class="text-center"  full-width v-click-outside="onClose">
                
                <v-card-title style="background: #F6F2FF; font-weight: bolder; font-size: x-large;">
                    {{ this.$t('relaseNote.relaseNote')}}   
                    <v-spacer></v-spacer>
                    <v-btn icon @click="showModal = false" >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                 
                <v-card-content>
                    
                    <div v-for="data in dataList" :key="data.tag_name" >
                        <v-col class="text-left" style="font-weight: bolder;">
                            <v-row>
                                <v-col class="ml-3">
                                    {{data.tag_name}}
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col class="text-left">
                            <v-row>
                                <v-col class="ml-5">
                                    <ul class="ml-5">
                                        <li v-for="i in data.description.split('\n')" :key="i">{{i.replace("-" , "")}}</li>
                                    </ul>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-divider class="ml-5 mr-5" style="background: #f2f2f3;"></v-divider>
                    </div>
                    
                    <v-col>
                        <v-btn
                            text
                            @click="onClose()"
                        >
                        
                        {{ this.$t('back')}}
                        </v-btn>
                        
                    </v-col>
                </v-card-content>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    export default {
        name : "ModalReleaseNote",
        props:{
            showModal : Boolean,
            dataList : Object
        },
    
        methods:{
            onClose(){
                this.$emit('onClose')
            },
        
        },
    }
</script>