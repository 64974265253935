const th = {
    firstName : "ชื่อ",
    lastName : "นามสกุล",
    back : "กลับ",
    relaseNote : {
        relaseNote : "รายงานการอัพเดท",
        lastUpdate : "อัพเดทล่าสุดเมื่อ" ,
        copyRight : "สร้างโดย"
    }
};

export default th;