// const { env } = require("process");

module.exports = {
    auth: {
        tenantId: `${process.env.VUE_APP_TENANT_ID}`,
        clientId: `${process.env.VUE_APP_CLIENT_ID}`,
        redirectUrl: `${process.env.VUE_APP_REDIRECT_URL}`,
    },
    authenService: {
        uri: `${process.env.VUE_APP_AUTHEN_URI}`,
        username: `${process.env.VUE_APP_AUTHEN_USERNAME}`,
        password: `${process.env.VUE_APP_AUTHEN_PASSWORD}`,
    },
    gateway: {
        uri: `${process.env.VUE_APP_GATEWAY_URI}`,
    },
    lmsManagementService: {
        uri: `${process.env.VUE_APP_LMS_MANAGEMENT_API_URL}`,
        username: `${process.env.VUE_APP_LMS_MANAGEMENT_API_USER}`,
        password: `${process.env.VUE_APP_LMS_MANAGEMENT_API_PASSWORD}`,
    },
    BaakService: {
        uri: `${process.env.VUE_APP_BAAK_API_URL}`,
        username: `${process.env.VUE_APP_BAAK_API_USER}`,
        password: `${process.env.VUE_APP_BAAK_API_PASSWORD}`,
    },
    gitlabService :{
        url : `${process.env.VUE_APP_GITLAB_PROJECT_URL}`,
        accessToken : `${process.env.VUE_APP_GITLAB_PROJECT_TOKEN}`
    },
    aesKey: {
        key: `${process.env.VUE_APP_LOCALSTORAGE_KEY}`
    },
    lmsConfig: {
        canvas:{
            termId: `${process.env.VUE_APP_CANVAS_THIS_TERMID}`,
            termStart: `${process.env.VUE_APP_TIMESTAMP_START_THIS_TERM}`,
            termEnd: `${process.env.VUE_APP_TIMESTAMP_END_THIS_TERM}`
        },
        examYearly:{
            termStart: `${process.env.VUE_APP_TIMESTAMP_START_YEARLY_TERM}`,
            termEnd: `${process.env.VUE_APP_TIMESTAMP_END_YEARLY_TERM}`,
            categoryId : `${process.env.VUE_APP_EXAM_YEARLY_CATEGORY}`
        },
        examNext:{
            termStart: `${process.env.VUE_APP_TIMESTAMP_START_NEXT_TERM}`,
            termEnd: `${process.env.VUE_APP_TIMESTAMP_END_NEXT_TERM}`,
            categoryId : `${process.env.VUE_APP_EXAM_NEXT_CATEGORY}`
        },
        exam:{
            termStart: `${process.env.VUE_APP_TIMESTAMP_START_THIS_TERM}`,
            termEnd: `${process.env.VUE_APP_TIMESTAMP_END_THIS_TERM}`,
            categoryId : `${process.env.VUE_APP_EXAM_THIS_CATEGORY}`
        },
        examPrev:{
            termStart: `${process.env.VUE_APP_TIMESTAMP_START_PREVIOUS_TERM}`,
            termEnd: `${process.env.VUE_APP_TIMESTAMP_END_PREVIOUS_TERM}`,
            categoryId : `${process.env.VUE_APP_EXAM_THIS_CATEGORY}`
        },
        moodle:{
            termId: `${process.env.VUE_APP_MOODLE_THIS_CATEGORY}`,
            termStart: `${process.env.VUE_APP_TIMESTAMP_START_THIS_TERM}`,
            termEnd: `${process.env.VUE_APP_TIMESTAMP_END_THIS_TERM}`
        },
        moodleNext:{
            termId: `${process.env.VUE_APP_MOODLE_NEXT_CATEGORY}`,
            termStart: `${process.env.VUE_APP_TIMESTAMP_START_NEXT_TERM}`,
            termEnd: `${process.env.VUE_APP_TIMESTAMP_END_NEXT_TERM}`
        },
        moodleYearly:{
            termId: `${process.env.VUE_APP_MOODLE_NEXT_CATEGORY}`,
            termStart: `${process.env.VUE_APP_TIMESTAMP_START_YEARLY_TERM}`,
            termEnd: `${process.env.VUE_APP_TIMESTAMP_END_YEARLY_TERM}`
        },
        mango:{
            termId: `${process.env.VUE_APP_MANGO_THIS_TERMID}`,
            termStart: `${process.env.VUE_APP_TIMESTAMP_START_THIS_TERM}`,
            termEnd: `${process.env.VUE_APP_TIMESTAMP_END_THIS_TERM}`
        },
        mangoNext:{
            termId: `${process.env.VUE_APP_MANGO_NEXT_TERMID}`,
            termStart: `${process.env.VUE_APP_TIMESTAMP_START_NEXT_TERM}`,
            termEnd: `${process.env.VUE_APP_TIMESTAMP_END_NEXT_TERM}`
        },
        mangoYearly:{
            termId: `${process.env.VUE_APP_MANGO_YEARLY_TERMID}`,
            termStart: `${process.env.VUE_APP_TIMESTAMP_START_YEARLY_TERM}`,
            termEnd: `${process.env.VUE_APP_TIMESTAMP_END_YEARLY_TERM}`
        },
        currentSemester: `${process.env.VUE_APP_CURRENT_SEMESTER}`,
        nextSemester: `${process.env.VUE_APP_NEXT_SEMESTER}`,
        yearlySemester : `${process.env.VUE_APP_YEARLY_SEMESTER}`
    },
    
}