import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import Vuex from 'vuex'
import vuexI18n from 'vuex-i18n';
import '../src/assets/scss/style.scss'
import 'vuetify/dist/vuetify.min.css'
// import { locale } from 'core-js'
var VueCookie = require('vue-cookie');
// 
const config = require(`@/config`)

Vue.config.productionTip = false


Vue.use(Vuex)
Vue.use(vuexI18n)
Vue.use(VueCookie);


import th from "@/locales/th";
import en from "@/locales/en";


const store = new Vuex.Store({
  state: {
    count: 0,
    sideBarIsShow: true,
    sideBarData: {},
    isOnCourse: true,
    userData: {},
    currentSemester: config.lmsConfig.currentSemester,
    nextSemester : config.lmsConfig.nextSemester , 
    yearlySemester : config.lmsConfig.yearlySemester
    // cryptoJS: CryptoJS
  },
  mutations: {
    increment (state) {
      state.count++
    },
    togglesideBar (state) {
      state.sideBarIsShow = !state.sideBarIsShow
      // console.log("togglesideBar:",state.sideBarIsShow)
    },
    setSideBarHide (state) {
      state.sideBarIsShow = false
      // console.log("togglesideBar:",state.sideBarIsShow)
    },
    setSideBarShow (state) {
      state.sideBarIsShow = true
      // console.log("togglesideBar:",state.sideBarIsShow)
    },
    setSideBarData (state, courseData) {
      state.sideBarData = courseData
    },
    setShowHamburge (state, ShowHamburge) {
      state.isOnCourse = ShowHamburge
    },
    setUserData (state, user) {
      state.userData = user
    },
    setCurrentSemester (state, semester) {
      state.currentSemester = semester
    }
  }
})

Vue.use(vuexI18n.plugin, store);


// add translations directly to the application
Vue.i18n.add('th', th);
Vue.i18n.add('en', en);

// set the start locale to use
Vue.i18n.set('en');


new Vue({
  store,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
