import { apiGateway } from "@/axios.js"

export default {
    async getProfile(accessToken) {
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`
            let response = await apiGateway.get(`/users/profile`, config)
            response['data'].status = true
            return response['data']
        } catch (error) {
            
            return {
                status: false,
                message: error.response.data.error.code
            }
        }
    },
    async getUserRegCourses (accessToken, query) {
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`
            let response = await apiGateway.get(`/courses?${query}`, config)
            response['data'].status = true
            return response['data']
        } catch (error) {
            return {
                status: false,
                message: error.response.data.error.code
            }
        }
    },
    async getCourseDetail (courseId, accessToken) {
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`
            let response = await apiGateway.get(`/courses/${courseId}`, config)
            response['data'].status = true
            return response['data']
        } catch (error) {
            return {
                status: false,
                message: error.response.data.error.code
            }
        }
    },
    async getCourseMappingList (courseId, module, accessToken) {
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`
            let response = await apiGateway.get(`/courses/${courseId}/mapping?module=${module}`, config)
            response['data'].status = true
            return response['data']
        } catch (error) {
            return {
                status: false,
                message: error.response.data.error.code
            }
        }
    },
    async getCourseMappingOne (courseId, mappingId, accessToken) {
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`
            let response = await apiGateway.get(`/courses/${courseId}/mapping?_id=${mappingId}`, config)
            response['data'].status = true
            return response['data']
        } catch (error) {
            return {
                status: false,
                message: error.response.data.error.code
            }
        }
    },
    async createCourseMapping(courseId, reqBody, accessToken) {
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`
            let response = await apiGateway.post(`/courses/${courseId}/mapping`, reqBody, config)
            response['data'].status = true
            return response['data']
        } catch (error) {
            return {
                status: false,
                message: error.response.data.error.code ? error.response.data.error.code : ''
            }
        }
    },
    async updateMapping(courseId, mappingId, reqBody, accessToken) {
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`
            let response = await apiGateway.put(`/courses/${courseId}/mapping/${mappingId}`, reqBody, config)
            response['data'].status = true
            return response['data']
        } catch (error) {
            return {
                status: false,
                message: error.response.data.error.code ? error.response.data.error.code : ''
            }
        }
    },
    async createChannels(reqBody, accessToken) {
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`
            let response = await apiGateway.post(`/channels`, reqBody, config)
            response['data'].status = true
            return response['data']
        } catch (error) {
            return {
                status: false,
                message: error.response.data.error.code ? error.response.data.error.code : ''
            }
        }
    },
    async createJobs(reqBody, accessToken) {
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`
            let response = await apiGateway.post(`/jobs`, reqBody, config)
            response['data'].status = true
            return response['data']
        } catch (error) {
            return {
                status: false,
                message: error.response.data.error.code ? error.response.data.error.code : ''
            }
        }
    },
    async getJobs (query, accessToken) {
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`
            let response = await apiGateway.get(`/jobs?${query}`, config)
            response['data'].status = true
            return response['data']
        } catch (error) {
            return {
                status: false,
                message: error.response.data.error.code
            }
        }
    },

    // async getCourseInSubjection (subjectionid, accessToken) {
    //     try {
    //         let config = JSON.parse('{}')
    //         config['headers'] = JSON.parse('{}')
    //         config['headers']['Authorization'] = `Bearer ${accessToken}`
    //         config['headers']['grant-type'] = `accessToken`
    //         let response = await apiGateway.get(`/subjections/${subjectionid}/courses`, config)
    //         response['data'].status = true
    //         return response['data']
    //     } catch (error) {
    //         return {
    //             status: false,
    //             message: error.response.data.error.code
    //         }
    //     }
    // },

    async getCourseInSubjection (accessToken , semester) {
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`
            let response = await apiGateway.get(`/subjections/grantoncourses/course?semester=${semester}`, config)
            response['data'].status = true
            return response['data']
        } catch (error) {
            return {
                status: false,
                message: error.response.data.error.code
            }
        }
    },
    async getCourseInSubjectionQuery (accessToken , query) {
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`
            config['headers']['Connection'] = `keep-alive`
            config['headers']['timeout'] = 10000
            let response = await apiGateway.get(`/subjections/grantoncourses/course?${query}`, config)
            response["data"].status = true
            return response["data"]
        } catch (error) {
            console.log("catch :" , error)
            return {
                status: false,
                message: error.response.data.error.code
            }
        }
    },

    async getAdminSubjection (accessToken) {
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`
            let response = await apiGateway.get(`/subjections`, config)
            response['data'].status = true
            return response['data']
        } catch (error) {
            return {
                status: false,
                message: error.response.data.error.code
            }
        }
    },

    async getAdminGrantOnCourses (accessToken) {
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`
            let response = await apiGateway.get(`/subjections/grantoncourses/detail`, config)
            response['data'].status = true
            return response['data']
        } catch (error) {
            return {
                status: false,
                message: error.response.data.error.code
            }
        }
    },
    async getExamlistbyUser(accessToken , userEmail){
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`

            let response = await apiGateway.get(`/GetExamListByUser?cmuitaccount=${userEmail}`, config)
            response['data'].status = true

            return response['data']
        } catch (error) {
            return {
                status: false,
                message: error.response.data.error.code
            }
        }

    },
    async getGrantAdmin(accessToken){
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`

            let response = await apiGateway.get(`/subjections/grantoncourses/detail`, config)
            response['data'].status = true

            return response['data']
        } catch (error) {
            return {
                status: false,
                message: error.response.data.error.code
            }
        }

    },
    async getInstructorBySection(accessToken , courseId){
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`

            let response = await apiGateway.get(`/courses/${courseId}/instructors/sections`, config)
            response['data'].status = true

            return response['data']
        } catch (error) {
            return {
                status: false,
                message: error.response.data.error.code
            }
        }

    },
    async getInstructor(accessToken , courseId){
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`

            let response = await apiGateway.get(`/courses/${courseId}/instructors`, config)
            response['data'].status = true

            return response['data']
        } catch (error) {
            return {
                status: false,
                message: error.response.data.error.code
            }
        }

    },
    async putGrantAdmin(accessToken ,reqBody , grantId){
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`

            let response = await apiGateway.put(`/subjections/grantoncourses/${grantId}`, reqBody , config)
            response['data'].status = true

            return response['data']
        } catch (error) {
            return {
                status: false,
                message: error.response.data.error.code
            }
        }

    },
    async getGrantAdminCMU(accessToken ){
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`

            let response = await apiGateway.get(`/subjections/grantoncourses` , config)
            response['data'].status = true

            return response['data']
        } catch (error) {
            return {
                status: false,
                message: error.response.data.error.code
            }
        }

    },
    async getAdminByCourse(accessToken , query ){
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`

            let response = await apiGateway.get(`/subjections/listAdminByCourse${query}` , config)
            response['data'].status = true

            return response['data']
        } catch (error) {
            return {
                status: false,
                message: error.response.data.error.code
            }
        }

    },
    async getAllFaculty(accessToken ){
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`

            let response = await apiGateway.get(`/faculty` , config)
            response['data'].status = true

            return response['data']
        } catch (error) {
            return {
                status: false,
                message: error.response.data.error.code
            }
        }

    },
    async putCourseMappingSync (courseId, mappingId, accessToken ,reqBody) {
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`
            let response = await apiGateway.put(`/courses/${courseId}/mapping/${mappingId}`,reqBody , config)
            response['data'].status = true
            return response['data']
        } catch (error) {
            return {
                status: false,
                message: error.response.data.error.code
            }
        }
    },
    async apiPostJobs (accessToken ,reqBody) {
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`
            let response = await apiGateway.post(`/jobs`, reqBody , config)
            response['data'].status = true
            return response['data']
        } catch (error) {
            return {
                status: false,
                message: error.response.data.error.code
            }
        }
    },
    async AddInstructionInSection(accessToken , courseId ,reqBody){
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`
            let response = await apiGateway.post(`/courses/${courseId}/instructors`, reqBody , config)
            response['data'].status = true
            return response['data']
        } catch (error) {
            return {
                status: false,
                message: error.response.data.error.code
            }
        }
    },
    async RemoveInstructionInSection(accessToken , courseId , instructorId){
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`
            let response = await apiGateway.delete(`/courses/${courseId}/instructors/${instructorId}` , config)
            response['data'].status = true
            return response['data']
        } catch (error) {
            return {
                status: false,
                message: error.response.data.error.code
            }
        }
    },
    async AllSyncCourse(accessToken , courseId , reqBody){
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`
            let response = await apiGateway.put(`/courses/${courseId}/mapping/all/sync` , reqBody ,config)
            response['data'].status = true
            return response['data']
        } catch (error) {
            return {
                status: false,
                message: error.response.data.error.code
            }
        }
    },
    async GetValidateUserInstructor(accessToken , email){
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`
            let response = await apiGateway.get(`/users/msUser/${email}` ,config)
            response['data'].status = true
            return response['data']
        } catch (error) {
            console.log("error")
            return {
                status: false
            }
        }
    },
    async CreateLmsMoodle(accessToken , regBody){
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`
            let response = await apiGateway.post(`/lms/moodle` , regBody , config)
            response['data'].status = true
            return response['data']
        } catch (error) {
            console.log("error",error)
            return {
                status: false,
                message: error.response.data.error.code
            }
        }
    },
    async CreateLmsExam(accessToken , regBody){
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`
            let response = await apiGateway.post(`/lms/exam` , regBody , config)
            response['data'].status = true
            return response['data']
        } catch (error) {
            console.log("error",error)
            return {
                status: false,
                message: error.response.data.error.code
            }
        }
    },
    async CreateLmsMango(accessToken , regBody){
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`
            let response = await apiGateway.post(`/lms/mango` , regBody , config)
            response['data'].status = true
            return response['data']
        } catch (error) {
            console.log("error")
            return {
                status: false,
                message: error.response.data.error.code
            }
        }
    },
    async GetAdminList(accessToken , query){
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`
            let response = await apiGateway.get(`/faculty/admin/adminofuser?${query}`, config)
            response['data'].status = true
            return response['data']
        } catch (error) {
            console.log("error")
            return {
                status: false,
                message: error.response.data.error.code
            }
        }
    },
    async getUserRegCoursesOfStudent (accessToken, query) {
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`
            let response = await apiGateway.get(`/students/courses?${query}`, config)
            response['data'].status = true
            return response['data']
        } catch (error) {
            return {
                status: false,
                message: error.response.data.error.code
            }
        }
    },
    async getCourseSpec(accessToken , courseId) {
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`
            let response = await apiGateway.get(`/courses/${courseId}/spec/link`, config)
            response['data'].status = true
            return response['data']
        } catch (error) {
            return {
                status: false,
                message: "ไม่พบรายละเอียดวิชา"
            }
        }
    },
    async CreateAdminCourse(accessToken , regBody) {
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`
            let response = await apiGateway.post(`/subjections/grantoncourses`,regBody , config)
            response['data'].status = true
            return response['data']
        } catch (error) {
            return {
                status: false,
                message: "ไม่พบรายละเอียดวิชา"
            }
        }
    },
    async GetAdminListManage(accessToken , query){
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`
            let response = await apiGateway.get(`/subjections/listAdminGrant${query}`, config)
            response['data'].status = true
            return response['data']
        } catch (error) {
            return {
                status: false,
                message: "ไม่พบรายละเอียด"
            }
        }
    },
    async CreateMappingCourse(accessToken , regBody){
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`
            let response = await apiGateway.post(`/subjections/matchingcourses`,regBody , config)
            response['data'].status = true
            return response['data']
        } catch (error) {
            return {
                status: false,
                message: "ไม่พบรายละเอียดวิชา"
            }
        }
    },
    async UpdateMappingCourse(accessToken , regBody){
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`
            let response = await apiGateway.put(`/subjections/matchingcourses`,regBody , config)
            response['data'].status = true
            return response['data']
        } catch (error) {
            return {
                status: false,
                message: "ไม่พบรายละเอียดวิชา"
            }
        }
    },
    async updateGrantOnCourse(accessToken , grantId , regBody){
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`
            let response = await apiGateway.put(`/subjections/grantoncourses/${grantId}`, regBody, config)
            response['data'].status = true
            return response['data']
        } catch (error) {
            return {
                status: false,
                message: "อัพเดทไม่สำเร็จ"
            }
        }

    },
    async deleteMappingCourse(accessToken  , courseId , mappingId){
        try {
            let config = JSON.parse('{}')
            config['headers'] = JSON.parse('{}')
            config['headers']['Authorization'] = `Bearer ${accessToken}`
            config['headers']['grant-type'] = `accessToken`
            let response = await apiGateway.delete(`/courses/${courseId}/mapping/${mappingId}` , config)
            response['data'].status = true
            return response['data']
        } catch (error) {
            return {
                status: false,
                message: "ไม่พบรายละเอียดวิชา"
            }
        }
    }
}